<template>
  <div class="app-container" ref='sendRecord' @scroll="scroll">
    <div>
      <div>
        <el-form :inline="true" class="demo-form-inline" @keyup.enter="batchSearch" >
          <el-form-item label="单位:">
            <el-select v-model="search.institutionId" size="medium" clearable filterable
                       @change="handleInstitution" placeholder="请选择单位" class="form-line-item"
                       ref="selectInstitution"
                       @visible-change="isShowSelectOptions"
            >
              <el-option v-for="(item,index) in institutionList" :key='index'
                         :label="item.institutionName" :value="item.institutionId"></el-option>
            </el-select>
            <!-- <el-select v-model="search.institutionId" size="medium" filterable remote reserve-keyword
                placeholder="请输入单位" :remote-method="remoteMethod" class="form-line-item"
                @change="handleInstitution" :loading="selectLoading">
                <el-option v-for="(item,index) in institutionList" :key='index'
                    :label="item.institutionName" :value="item.institutionId"></el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="批次:">
            <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                       class="form-line-item" :disabled='batchShow'
                       ref="selectBatchId"
                       @visible-change="isShowSelectOptions"
            >
              <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                         :value="item.batchId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="论文题目:">
            <el-input placeholder="请输入论文题目" style="width:350px;" v-model="search.title" size='medium'
                      class="form-line-item">
            </el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-select v-model="search.haveNote" size="medium" clearable placeholder="请选择备注状态"
                       class="form-line-item"
                       ref="selectHaveNote"
                       @visible-change="isShowSelectOptions">
              <el-option label="带备注" :value="1"></el-option>
              <el-option label="无备注" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="学科:">
              <el-input placeholder="请输入学科" v-model="search.major" size='medium' class="form-line-item">
              </el-input>
          </el-form-item> -->
        </el-form>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="编号:">
            <el-input placeholder="请输入论文编号" v-model="search.oldId" size='medium' class="form-line-item">
            </el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="search.state" size="medium" clearable placeholder="请选择状态"
                       class="form-line-item"
                       ref="selectState"
                       @visible-change="isShowSelectOptions"
            >
              <el-option label="待匹配" :value="4"></el-option>
              <el-option label="待送审" :value="0"></el-option>
              <el-option label="送审中" :value="1"></el-option>
              <el-option label="评审中" :value="2"></el-option>
              <el-option label="已完成" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="学位类型:">
              <el-input placeholder="请输入学位类型" v-model="search.paperType" size='medium' class="form-line-item">
              </el-input>
          </el-form-item> -->
          <el-form-item label="学科:">
            <el-input placeholder="请输入学科" v-model="search.major" size='medium' class="form-line-item">
            </el-input>
          </el-form-item>
          <el-form-item label="送审方式:">
            <el-select v-model="search.sendingType" size="medium" clearable placeholder="请选择送审方式"
                       class="form-line-item"
                       ref="selectSendingType"
                       @visible-change="isShowSelectOptions"
            >
              <el-option label="人工送审" :value="3"></el-option>
              <el-option label="自动送审" :value="1"></el-option>
              <el-option label="人工干预" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left:10px;">
            <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
              搜索
            </el-button>
            <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 表格 -->
    <div>
      <!-- :loading="loading" -->
      <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar paperPageList" round align="left"
                 ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                 :checkbox-config="{trigger: 'cell', highlight: true, range: false}" show-footer
                 :footer-method="footerMethod" :merge-footer-items="mergeFooterItems" :loading='loading'
                 :data="paperList" >
        <vxe-column type='checkbox' width="50" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="title" title="题目" min-width="100" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="oldId" title="编号" width="120" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="institutionName" title="单位" width="120" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="batchName" title="批次" width="120" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="paperType" title="学位类型" width="100" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="subjectMajor" title="学科" width="100" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="pendingNum" title="待匹配" width="90" sortable>
          <template #default="{ row }">
            {{row.pendingNum}}/{{row.expertNumber}}
          </template>
        </vxe-column>
        <vxe-column field="waitingNum" title="待送审" width="90" sortable>
          <template #default="{ row }">
            {{row.waitingNum}}/{{row.expertNumber}}
          </template>
        </vxe-column>
        <vxe-column field="sendingNum" title="送审中" width="90" sortable>
          <template #default="{ row }">
            {{row.sendingNum}}/{{row.expertNumber}}
          </template>
        </vxe-column>
        <vxe-column field="reviewNum" title="评审中" width="90" sortable>
          <template #default="{ row }">
            {{row.reviewNum}}/{{row.expertNumber}}
          </template>
        </vxe-column>
        <vxe-column field="finishedNum" title="已完成" width="90" sortable>
          <template #default="{ row }">
            {{row.finishedNum}}/{{row.expertNumber}}
          </template>
        </vxe-column>
        <vxe-column field="endTime" title="截止时间" width="120" :show-overflow="'tooltip'">
          <!-- <template #default="{row}">
              <div :style="{color:autoPaperIds.indexOf(row.paperId) == -1?'':'red'}">{{row.endTime }}</div>
          </template> -->
        </vxe-column>
        <vxe-column field="returnTime" title="院校返回时间" width="120" :show-overflow="'tooltip'">
          <!-- <template #default="{row}">
              <div :style="{color:autoPaperIds.indexOf(row.paperId) == -1?'':'red'}">{{row.endTime }}</div>
          </template> -->
        </vxe-column>
        <vxe-column title="操作" field="note" width="220" fixed="right" sortable>
          <template #default="{ row }">
            <el-button size="mini" type="text" @click="getWorkLogs(row)">
              查看日志
            </el-button>

            <!-- <i :class="row.matchType == 3?'el-icon-user-solid':row.matchType == 1?'el-icon-star-on':'el-icon-star-off'" @click="autoSendingHandle(row)" style="padding-left: 5px;color: #1890ff;"></i> -->
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager perfect align='right' :current-page.sync="page.pageIndex" :page-size.sync="page.pageSize"
                 :total="page.total" :page-sizes="page.pageSizes"
                 :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                 @page-change="handlePageChange">
        <template #left>
          <vxe-button size="small" @click="firstPage">首页
          </vxe-button>
        </template>
      </vxe-pager>
    </div>

    <el-drawer title="选择专家" :visible.sync="drawer" size="750">
      <template slot="title">
        <div class='titleDia'>
          工作日志
        </div>
      </template>
      <div style="margin:10px;margin-top:0px;">
        <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar insertList" round align="center"
                   :data="workLogs" highlight-current-row highlight-hover-row element-loading-text="拼命加载中" :loading='drawerLoading'
                   element-loading-spinner="el-icon-loading">
          <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
          <vxe-column field="sysUserName" title="工作人员" width="200" :show-overflow="'tooltip'"></vxe-column>
          <vxe-column field="workTime" title="工作时间" width="250" :show-overflow="'tooltip'">
          </vxe-column>
          <vxe-column field="workContent" title="工作内容" width="150" :show-overflow="'tooltip'">
          </vxe-column>
        </vxe-table>
      </div>

    </el-drawer>
  </div>
</template>

<script>
import axios from 'axios';
import XLSX from 'xlsx';
import { Loading } from 'element-ui';
// import dateFormat from '../../../public/tools/date'
export default {
  name: 'workLogs',
  data() {
    return {
      editDeadlineModal: false,
      editRuleModal: false,
      editNoteModal: false,
      uploadSendingRuleModel: false,
      //数据加载
      loading: false,
      select: '1',
      //搜索条件
      institutionList: [],
      batchList: [],
      search: {
        institutionId: '',
        batchId: '',
        title: '',
        oldId: '',
        paperType: '',
        // author: '',
        state: '',
        major: '',
        haveNote:'',
        sendingType:''
      },
      batchShow: true,
      //论文列表
      paperList: [
        //     {
        //     paperId: 1,
        //     title: '论文题目',
        //     institutionName: '南京理工大学',
        //     batchName: '2021硕士第一批',
        //     // statue: '送审中',
        //     endTime: '2022-10-10',
        //     waitingNum: 0,
        //     totalReviewNum: 0,
        //     finishedNum: 0,
        //     reviewNum: 0
        // }
      ],
      mergeFooterItems: [{
        row: 0,
        col: 0,
        rowspan: 0,
        colspan: 6
      }],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        pageCount: 40, //自己用
        pageSizes: [10, 20, 50, 100, 200, 500, {
          label: '全部数据',
          value: 10000
        }],
        total: 0
      },
      //评阅书列表
      receiptList: [{
        expertName: 'sh',
        university: '正高',
        state: '0',
        resultKeywords: '',
        paperKeywords: ''
      }],
      paperKeywords: '',
      //发送进度条
      sendProcessVis: false,
      showClose: false,
      sendProcessTitle: '发送中',
      process: 0,
      sendTimer: '',
      expertLevel: [],
      selectLoading: false,
      editPaper: {
        endData: '',
        sendingNumber: '',
        mappingMajor: ''
      },
      cancelToken: {
        getP2pPaperSource: null
      },
      levels: ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-','其他'],
      // 单篇送审规则
      sendingRule: {
        "universityLevel": [],
        "supervisorType": [],
        "expertTitle": [],
        "debarb": "0",
        "appoint": "0",
        "sendingNum": "2",
        "localSendingNum": "0",
        "expertReviewNum": "2",
        "debarbUniversity": "",
        "appointUniversity": "",
        "appointRegion": '',
        "debarbRegion": '',
        "universityRanking": ['', ''],
      },
      options: [],
      // 论文信息
      paperInfo: {
        paperId:'',
        note: ''
      },
      quality: ['A','B','C'],
      accept: ['A','B','C'],
      frequency: ['A','B','C'],
      responsibility: ['A','B','C'],
      singularity: ['A','B','C'],
      mean:['A','B','C','D','E'],
      standard_deviation:['A','B','C','D','E'],
      isSpare:0,
      isSecondMajor:0,
      // 需要修改的备注的id，
      locations:'',
      // 选中带论文
      // selectNotes:[],
      // selectNotesModal:false，
      autoSending:{
        quality: ['A','B','C'],
        accept: ['A','B','C'],
        frequency: ['A','B','C'],
        responsibility: ['A','B','C'],
        singularity: ['A','B','C'],
        mean:['A','B','C','D','E'],
        standard_deviation:['A','B','C','D','E'],
        isSpare:0,
        isSecondMajor:0,
        days:0
      },
      paperLength: 0,
      count: 0,
      paperPercent: 0,
      matchText:'',
      matchLoading:'',
      autoPaperIds:[],
      autoSendingDetail:{},
      drawer: false,
      workLogs:{},
      ws:null
    }
  },
  mounted() {

  },
  activated() {
    // this.resetHandle();
    this.handleInstitutionChange();

    this.getP2pPaper();
    // websocket建立
    this.openWebSocket();
  },
  // activated() {
  //     this.$nextTick(() => {
  //         console.log(this.$store.state.sendRecord.scrollTop);
  //         this.$refs.sendRecord.parentElement.scrollTop = this.$store.state.sendRecord.scrollTop;
  //     })
  // },
  created() {
    window.addEventListener('keydown', this.handleKeyPress);
    this.getFilterOptions();

    this.getLocation();
    // this.getP2pPaper();
  },
  destroyed(){
    // clearInterval(this.timer);
    this.ws.close()
  },
  deactivated(){
    this.ws.close();
  },
  methods: {
    handleKeyPress(event) {
      if (event.keyCode === 13) {
        this.batchSearch()
      }
    },
    // 获取选项列表
    getFilterOptions() {
      this.$api.schoolCustom.getFilterOptions().then(res => {
        if (res.data.code = 200) {
          this.options = res.data.data;
        }
      }).catch(err => {
        this.$message.warning("请求出错");
      })
    },
    getLocation(){
      this.$api.schoolCustom.getLocation()
          .then(res=>{
            if(res.data.code == 200){
              this.locations = res.data.data;
            }
          })
    },
    // 获取论文列表
    getP2pPaper() {
      console.log('取消', this.cancelToken.getP2pPaperSource);
      if (this.cancelToken.getP2pPaperSource) {
        this.cancelToken.getP2pPaperSource.cancel();
        this.cancelToken.getP2pPaperSource = null;
      }
      this.loading = true;
      let param = new URLSearchParams();
      param.append("institutionId", this.search.institutionId);
      param.append("batchId", this.search.batchId);
      param.append("state", this.search.state);
      param.append("title", this.search.title);
      param.append("oldId", this.search.oldId);
      param.append("major", this.search.major);
      param.append("haveNote", this.search.haveNote);
      param.append('matchType',this.search.sendingType);
      param.append("paperTye", this.search.paperType);
      param.append("pageIndex", this.page.pageIndex);
      param.append("pageSize", this.page.pageSize);
      param.append("isFiled", 0);
      const cancelToken = axios.CancelToken;
      this.cancelToken.getP2pPaperSource = cancelToken.source();
      console.log('赋值', this.cancelToken.getP2pPaperSource);
      this.$api.sendingP2p.getP2pPaper(param)
          .then(res => {
            console.log(res);
            this.cancelToken.getP2pPaperSource = null;
            if (res.data.code == 200) {
              console.log(res.data);
              this.autoPaperIds = res.data.data.autoPaperIds;
              this.paperList = res.data.data.data;
              this.page.total = res.data.count;
              this.loading = false;
            }
          }, err => {
            // this.cancelToken.getP2pPaperSource = null;
            // this.$message.warning("服务器维护111");
          })
    },
    //表格刷新
    refresh() {
      this.getP2pPaper();
    },
    match(params){
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      this.paperLength = selectRecords.length;
      if (selectRecords.length == 0) {
        this.$message.warning("请选择论文");
        return;
      }
      let idInfos = [];
      let notes = [];
      selectRecords.forEach((item) => {
        idInfos.push({
          paperId: item.paperId
        });
        if(item.note){
          notes.push(item);
        }
      })
      params["idInfos"] = idInfos;
      const startMatch = (params)=>{
        this.matchText = "正在匹配中 "+ this.count + "/" + this.paperLength
        this.matchLoading = Loading.service({fullscreen: true, text: '正在上传'})
        this.matchLoading.setText(this.matchText)
        // let loading = this.$loading({
        //     lock: true,
        //     text: this.matchText,
        //     spinner: 'el-icon-loading',
        //     background: 'rgba(0, 0, 0, 0.7)'
        // });
        this.$api.sendingP2p.getMatch(params)
            .then(res => {
              this.matchLoading.close();
              if (res.data.code == 200) {
                this.$message.success("匹配成功！");
                this.getP2pPaper();
              } else {
                this.$message.warning("匹配失败!");
              }

            }).catch(err => {
          this.matchLoading.close();
          this.$message.warning("服务器维护！");
        })
      }
      if(notes.length > 0){
        this.$confirm('选中包含带备注论文，是否继续匹配?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          startMatch(params);
        }).catch(() => {
          this.$message({
            type: 'success',
            message: '已取消'
          });
        });
      }else{
        startMatch(params);
      }
    },
    matchHandle() {
      let params = {
        matchType: 'out',
        accept: this.accept.join(','),
        frequency: this.frequency.join(','),
        quality: this.quality.join(','),
        responsibility: this.responsibility.join(','),
        singularity: this.singularity.join(','),
        mean: this.mean.join(','),
        standard_deviation: this.standard_deviation.join(','),
        isSpare:this.isSpare,
        isSecondMajor:this.isSecondMajor,
        isUsed:0,
        resultIds: null
      };
      this.match(params);
    },
    autoMatch(params){
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      if (selectRecords.length == 0) {
        this.$message.warning("请选择论文");
        return;
      }
      let paperIds = [];
      let notes = [];
      selectRecords.forEach((item) => {
        paperIds.push(item.paperId);
        if(item.note){
          notes.push(item);
        }
      })
      params["paperIds"] = paperIds;
      const startMatch = (params)=>{
        const loading = this.$loading({
          lock: true,
          text: '正在开始自动送审',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$api.sendingP2p.postAutoSending(params)
            .then(res => {
              loading.close();
              if (res.data.code == 200) {
                this.$message.success("开始自动送审成功！");
                this.getP2pPaper();
              } else {
                this.$message.warning("开始自动送审失败!");
              }
              this.$refs.popover2.doClose();
            }).catch(err => {
          loading.close();
          this.$message.warning("服务器维护！");
        })
      }
      if(notes.length > 0){
        this.$confirm('选中包含带备注论文，是否继续匹配?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          startMatch(params);
        }).catch(() => {
          this.$message({
            type: 'success',
            message: '已取消'
          });
        });
      }else{
        startMatch(params);
      }
    },
    autoMatchHandle(){
      let params = {
        accept: this.autoSending.accept.join(','),
        frequency: this.autoSending.frequency.join(','),
        quality: this.autoSending.quality.join(','),
        responsibility: this.autoSending.responsibility.join(','),
        singularity: this.autoSending.singularity.join(','),
        tightness: this.autoSending.tightness.join(','),
        isSpare:this.autoSending.isSpare,
        isSecondMajor:this.autoSending.isSecondMajor,
        isUsed:0,
        days:this.autoSending.days
      }
      this.autoMatch(params);
    },
    calcelAutoSending(){
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      if (selectRecords.length == 0) {
        this.$message.warning("请选择论文");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: '正在取消',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let paperIds = [];
      selectRecords.forEach((item) => {
        paperIds.push(item.paperId);
      })
      this.$api.sendingP2p.postCancalAutoSending(paperIds)
          .then(res => {
            loading.close();
            if (res.data.code == 200) {
              this.$message.success("取消成功！");
              this.getP2pPaper();
            } else {
              this.$message.warning("取消失败!");
            }
          }).catch(err => {
        loading.close();
        this.$message.warning("服务器维护！");
      })
    },
    acceptedMatchHandle(){
      let params = {
        matchType: 'out',
        accept: '',
        frequency: '',
        quality:'',
        responsibility:'',
        singularity:'',
        mean:'',
        standard_deviation:'',
        isSpare:0,
        isSecondMajorr:0,
        isUsed:1,
        resultIds: null
      };
      this.match(params);
    },
    SpareMatchHandle(){
      let params = {
        matchType: 'out',
        accept: '',
        frequency: '',
        quality: '',
        responsibility:'',
        singularity:'',
        mean:'',
        standard_deviation:'',
        isSpare:1,
        isSecondMajor:0,
        isUsed:0,
        resultIds: null
      };
      this.match(params);
    },

    // 导出送审记录
    exportSending() {
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      if (selectRecords.length == 0) {
        this.$message.warning("请选择论文");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: '导出中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let idInfos = [];
      selectRecords.forEach((item) => {
        idInfos.push(item.paperId);
      })
      let params = {
        paperIds: idInfos.join(',')
      }
      this.$api.sendingP2p.generateSendingTable(params)
          .then(res => {
            if (res.data.code == 200) {
              let p = {
                path: res.data.data
              }
              this.$api.reviewCheck.getTables(p)
                  .then(res1 => {
                    const blob = new Blob([res1.data], {
                      type: 'Application/vnd.ms-excel;charset=utf-8'
                    });
                    let url = window.URL || window.webkitURL;
                    const href = url.createObjectURL(blob);
                    let downloadElement = document.createElement('a');
                    downloadElement.href = href;
                    downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    loading.close();
                  }).catch(err => {
                this.$message.warning("导出excel错误！");
              })
            }
          })
          .catch(err => {
            this.$message.warning("导出评审记录错误");
          })
    },
    getFileName(str) {
      return str.substr(str.indexOf('\\') + 1)
    },
    //获取送审单位
    async getInstitution() {
      // let params = {
      //     isFiled : 0
      // }
      let param = new URLSearchParams();
      param.append("isFiled", 0);
      await this.$api.schoolCustom.getSendingInstitution(param)
          .then(res => {
            console.log(res);
            this.institutionList = res.data.data;
            console.log(this.institutionList);
            // this.search.institutionId = this.institutionList[0].institutionId;
            // this.handleInstitution();
            // this.selectLoading = false;
          })
          .catch(err => {
            this.$message.warning('接口错误');
          })
    },
    //获取送审单位的批次
    async getBatch(institutionId) {
      let param = new URLSearchParams();
      param.append("institutionId", institutionId);
      param.append("isFiled",0);
      await this.$api.sendingP2p.getBatch(param)
          .then(res => {
            console.log(res.data.data)
            this.batchList = res.data.data;
          })
          .catch(err => {
            this.$message.warning("服务器维护");
          });
    },
    //机构选择改变
    handleInstitution(val) {
      this.search.batchId = '';
      if (val == '') {
        this.batchShow = true;
        return;
      }
      this.batchShow = false;
      this.getBatch(val);
    },

    // 下载论文原件
    downloadPaper() {
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      if (selectRecords.length <= 0) {
        this.$message.warning('请选择论文！');
        return;
      }
    },
    // 修改截止时间
    editDeadline() {
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      if (selectRecords.length <= 0) {
        this.$message.warning('请选择论文！');
        return;
      }
      this.editPaper = {
        paperIds: '',
        endData: '',
        sendingNumber: '',
        mappingMajor: ''
      }
      this.editDeadlineModal = true;
    },
    cfmEdit() {
      if (this.editPaper.endData == '' && this.editPaper.sendingNumber == '' && this.editPaper.mappingMajor ==
          '') {
        this.$message.warning("至少修改一项");
        return;
      }
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      let paperIds = [];
      selectRecords.forEach((item) => {
        paperIds.push(item.paperId);
      });
      let params = {
        paperIds: paperIds.join(','),
        endData: this.dateFormat(this.editPaper.endData),
        sendingNumber: this.editPaper.sendingNumber,
        mappingMajor: this.editPaper.mappingMajor
      }
      this.$api.sendingP2p.postModifyConditions(params)
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("修改成功");
              this.editDeadlineModal = false;
            }
          }).catch(err => {
        this.$message.warning("修改截止日期错误");
      })
    },
    autoSendingHandle(row){
      let params = {
        paperId:row.paperId
      };
      function changeTime(date){
        if(!date || date.length < 19) return date;
        return date.substr(0,19).replace('T',' ');
      }
      this.$api.sendingP2p.getAutoDetail(params)
          .then(res=>{
            if(res.data.data) this.autoSendingDetail = res.data.data;
            else this.autoSendingDetail = {
              startTime:'',
              endTime:'',
              days:''
            }
            this.autoSendingDetail.startTime = changeTime(this.autoSendingDetail.startTime)
            this.autoSendingDetail.endTime = changeTime(this.autoSendingDetail.endTime)
          })
    },
    getSingleSendingRule(paperId) {
      let param = {
        paperId: paperId
      }
      this.$api.sendingP2p.getSingleSendingRule(param)
          .then(res => {
            let item = res.data.data;
            this.sendingRule = {
              'paperId': paperId,
              "universityLevel": item.universityLevel ? item.universityLevel.split('、') : [],
              "expertType": item.expertType ? item.expertType.split('、') : [],
              "expertTitle": item.expertTitle ? item.expertTitle.split('、') : [],
              "debarb": item.debarb,
              "appoint": item.appoint,
              "sendingNum": item.sendingNum,
              "localSendingNum": item.localSendingNum,
              "expertReviewNum": item.expertReviewNum,
              "pattern": item.pattern,
              "debarbUniversity": item.debarbUniversity,
              "appointUniversity": item.appointUniversity,
              "mappingMajor": item.mappingMajor,
              "majorGrade": item.majorGrade?item.majorGrade.split('、'):[],
              "appointRegion": item.appointRegion?item.appointRegion.split('、'):[],
              "debarbRegion": item.debarbRegion?item.debarbRegion.split('、'):[],
              "universityRanking": item.ranking?item.ranking.split('-'):['',''],
            }
          })
    },
    resetRule() {
      this.sendingRule = {
        'paperId': '',
        "universityLevel": [],
        "supervisorType": [],
        "expertTitle": [],
        "debarb": "0",
        "appoint": "0",
        "sendingNum": "2",
        "localSendingNum": "0",
        "expertReviewNum": "2",
        "debarbUniversity": "",
        "appointUniversity": "",
        "appointRegion": '',
        "debarbRegion": '',
        "universityRanking": ['', ''],
      }
    },
    editSendingRuleHandle(paperId) {
      this.resetRule();
      this.getSingleSendingRule(paperId);
      this.editRuleModal = true;
    },
    cfmEditSingleRule() {
      let params = [{
        'paperId': this.sendingRule.paperId,
        "universityLevel": this.sendingRule.universityLevel.join('、'),
        "expertType": this.sendingRule.expertType.join('、'),
        "expertTitle": this.sendingRule.expertTitle.join('、'),
        "appointRegion": this.sendingRule.appointRegion?this.sendingRule.appointRegion.join('、'):'',
        "debarbRegion": this.sendingRule.debarbRegion?this.sendingRule.debarbRegion.join('、'):'',
        "universityRanking": this.sendingRule.universityRanking[0] && this.sendingRule.universityRanking[1]?this.sendingRule.universityRanking.join('-'):'',
        "debarb": this.sendingRule.debarb,
        "appoint": this.sendingRule.appoint,
        "sendingNum": this.sendingRule.sendingNum,
        "localSendingNum": this.sendingRule.localSendingNum,
        "expertReviewNum": this.sendingRule.expertReviewNum,
        "pattern": this.sendingRule.pattern,
        "debarbUniversity": this.sendingRule.debarbUniversity,
        "appointUniversity": this.sendingRule.appointUniversity,
        "mappingMajor": this.sendingRule.mappingMajor,
        "majorGrade": this.sendingRule.majorGrade.join('、')
      }]
      this.$api.sendingP2p.postModifyCondition(params)
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("修改成功");
              this.editRuleModal = false;
            } else {
              this.$message.warning("修改失败");
            }
          }).catch(err => {
        this.editRuleModal = false;
      })
    },
    // 修改论文信息
    editNoteHandle(row) {
      this.paperInfo.paperId = row.paperId;
      this.paperInfo.note = row.note;
      this.editNoteModal = true;
    },
    cfmEditNote(){
      let params = {
        paperId:this.paperInfo.paperId,
        note:this.paperInfo.note
      }
      this.$api.sendingP2p.setPaperNote(params)
          .then(res=>{
            if(res.data.code == 200){
              this.$message.success("添加成功！");
              this.editNoteModal = false;
              this.getP2pPaper();
            }
          })
    },
    downloadSendingRule() {
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      if (selectRecords.length <= 0) {
        this.$message.warning('请选择论文！');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: '下载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let paperIds = [];
      selectRecords.forEach((item) => {
        paperIds.push(item.paperId);
      });
      let param = {
        paperIds: paperIds.join(','),
      };
      this.$api.sendingP2p.downloadPaperCondition(param)
          .then(res => {
            if (res.data.code == 200) {
              let p = {
                path: res.data.data
              }
              this.$api.reviewCheck.getTables(p)
                  .then(res1 => {
                    const blob = new Blob([res1.data], {
                      type: 'Application/vnd.ms-excel;charset=utf-8'
                    });
                    let url = window.URL || window.webkitURL;
                    const href = url.createObjectURL(blob);
                    let downloadElement = document.createElement('a');
                    downloadElement.href = href;
                    downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    loading.close();
                  }).catch(err => {
                this.$message.warning("导出excel错误！");
              })
            }
          })
          .catch(err => {
            this.$message.warning("导出评审记录错误");
          })

    },
    uploadSendingRule() {
      this.uploadSendingRuleModel = true;
    },
    cfmUploadSendingRule() {
      if (this.$refs.paperInfo.uploadFiles.length < 1) {
        this.$message.warning("请选择审核信息");
        return;
      }
      var xlsFile = this.$refs.paperInfo.uploadFiles[0];

      const loading = this.$loading({
        lock: true,
        text: '正在上传',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var reader = new FileReader(); //读取文件内容
      reader.readAsArrayBuffer(xlsFile.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
      reader.onload = (e) => {
        console.log(this.batchId);
        var dd = e.target.result;
        var workbook = XLSX.read(dd, {
          type: 'buffer'
        });
        var sheetname = workbook.SheetNames[0]
        var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname])
        var paperDetails = [];
        for (let i in tables) {
          let row = tables[i];
          var paps = {
            'paperId': typeof (row["论文id"]) == 'undefined' ? '' : row[
                "论文id"],
            "universityLevel": typeof (row["院校级别"]) == 'undefined' ? '' : row[
                "院校级别"],
            "expertType": typeof (row["专家类型"]) == 'undefined' ? '' : row[
                "专家类型"],
            "expertTitle": typeof (row["专家职称"]) == 'undefined' ? '' : row[
                "专家职称"],
            "debarb": typeof (row["是否回避院校"]) == 'undefined' ? '' : row[
                "是否回避院校"],
            "appoint": typeof (row["是否指定院校"]) == 'undefined' ? '' : row[
                "是否指定院校"],
            "sendingNum": typeof (row["送审意见数"]) == 'undefined' ? '' : row[
                "送审意见数"],
            "localSendingNum": typeof (row["本校意见数"]) == 'undefined' ? '' : row[
                "评阅书ID"],
            "expertReviewNum": typeof (row["专家最大接审数"]) == 'undefined' ? '' : row[
                "专家最大接审数"],
            "pattern": typeof (row["送审模式"]) == 'undefined' ? '' : row[
                "评阅书ID"],
            "debarbUniversity": typeof (row["回避院校"]) == 'undefined' ? '' : row[
                "回避院校"],
            "appointUniversity": typeof (row["指定院校"]) == 'undefined' ? '' : row[
                "指定院校"],
            "mappingMajor": typeof (row["映射学科"]) == 'undefined' ? '' : row[
                "映射学科"],
            "majorGrade": typeof (row["学科匹配等级"]) == 'undefined' ? '' : row[
                "学科匹配等级"],
            "rankings": typeof (row["院校排名"]) == 'undefined' ? '' : row[
                "院校排名"],
            "debarbRegion": typeof (row["回避地区"]) == 'undefined' ? '' : row[
                "回避地区"],
            "appointRegion": typeof (row["指定地区"]) == 'undefined' ? '' : row[
                "指定地区"]
          };
          paperDetails.push(paps);
        };
        this.$api.sendingP2p.postModifyCondition(paperDetails)
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("修改成功");
                this.uploadSendingRuleModel = false;
              } else {
                this.$message.warning("修改失败");
              }
              loading.close();
            }).catch(err => {
          // this.editRuleModal = false;
          this.$message.warning("上传失败");
          loading.close();
        })

      }
    },
    dateFormat(time) {
      if (time == '') return '';
      var datetime = new Date(time);
      // datetime.setTime(time);
      var year = datetime.getFullYear();
      var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() +
          1;
      var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
      return year + "-" + month + "-" + date;
    },
    // 发送催审邮件
    sendUrgeMail() {
      //获取已选中的论文
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      if (selectRecords.length <= 0) {
        this.$message.warning('请选择论文！');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: '正在发送',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let paperIds = [];
      selectRecords.forEach((item) => {
        paperIds.push(item.paperId);
      });
      let param = {
        paperIds: paperIds.join(','),
      };

      this.$api.sendingP2p.sendUrgeMail(param)
          .then(res => {
            loading.close();
            if (res.data.code == 200) {
              this.$message.success("发送成功");
            } else {
              this.$message.warning("发送失败");
            }
          }).catch(err => {
        this.$message.warning("发送失败");
      })
    },
    // 获取日志信息
    getWorkLogs(row){
      console.log(row);
      console.log(row.paperId);
      let param = new URLSearchParams();
      param.append('paperId', row.paperId);
      console.log(param)
      this.$api.user.getWorkLogs(param)
          .then(res => {
            if (res.data.code == 200){
              this.workLogs = res.data.data;
              this.drawer = true;
            }
          }).catch(err => {
            this.$message.warning("服务器维护");
      })
    },
    //发送邀审信息
    sendEmailHandle(paperId) {
      //获取已选中的论文
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      if (selectRecords.length <= 0) {
        this.$message.warning('请选择论文！');
        // this.refresh();
        return;
      }
      // this.process = 0;
      // this.sendProcessVis = true;
      const loading = this.$loading({
        lock: true,
        text: '正在发送',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let paperIds = [];
      selectRecords.forEach((item) => {
        paperIds.push(item.paperId);
      });
      let param = {
        paperIds: paperIds.join(','),
      };

      this.$api.sendingP2p.getSendEmail(param)
          .then(res => {
            loading.close();
            // this.showClose = true;
            if (res.data.code == 200) {
              // this.process = 100;
              // this.sendProcessTitle = '发送成功';
              this.$message.success("发送成功");
              this.refresh();

            } else {
              // this.process = 99;
              // this.sendProcessTitle = '发送失败';
              this.$message.warning("发送失败");
              this.refresh();
            }
          }).catch(err => {
        this.$message.warning("发送失败");
        this.refresh();
      })

      // this.$axios.post("/sending_paper/send_email", param)
      //     .then(res => {
      //         // clearInteral(this.sendTimer);
      //         loading.close();
      //         this.showClose = true;
      //         if (res.data.code == 1) {
      //             this.process = 100;
      //             this.sendProcessTitle = '发送成功';
      //             this.$message.success("发送成功");
      //         } else {
      //             this.process = 99;
      //             this.sendProcessTitle = '发送失败';
      //             this.$message.warning("发送失败");
      //         }
      //     })
      //     .catch(err => {
      //         loading.close();
      //         this.$message.warning("服务器维护！");
      //     });
    },
    //获取发送精度
    getProcess() {
      this.$axios.get('sending_paper/progress')
          .then(res => {
            this.process = res.data.data;
          }).catch(err => {
        this.$message.warning("服务器维护");
      })
    },
    batchSearch() {

      this.page.pageIndex = 1;
      this.getP2pPaper();
    },
    resetHandle() {
      this.search = {
        institutionId: '',
        batchId: '',
        title: '',
        oldId: '',
        student: '',
        state: '',
        paperType: '',
        major: '',
        haveNote:'',
        sendingType:''
      };
    },
    //分页操作
    handlePageChange({
                       currentPage,
                       pageSize
                     }) {
      this.page.pageIndex = currentPage;
      this.page.pageSize = pageSize;
      this.getP2pPaper();
    },
    firstPage() {
      this.page.pageIndex = 1;
      this.getP2pPaper();
    },
    endPage() {
      this.page.pageIndex = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
          .pageSize == 0 ? 0 : 1);
      this.getP2pPaper();
    },
    //获取选中的论文
    getSelectEvent() {
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
    },
    footerMethod({
                   columns,
                   data
                 }) {
      const sums = [];
      let finishedNum = 0,
          reviewNum = 0,
          waitingNum = 0,
          expertNumber = 0,
          pendingNum = 0,
          sendingNum = 0;
      data.forEach(item => {
        finishedNum += item.finishedNum;
        reviewNum += item.reviewNum;
        waitingNum += item.waitingNum;
        sendingNum += item.sendingNum;
        pendingNum += item.pendingNum;
        expertNumber += item.expertNumber;
      })
      columns.forEach((column, columnIndex) => {
        if (columnIndex === 0) {
          sums.push('总计')
        } else {
          switch (column.property) {
            case 'waitingNum':
              sums.push(waitingNum + '/' + expertNumber);
              break
            case 'sendingNum':
              sums.push(sendingNum + '/' + expertNumber);
              break
            case 'reviewNum':
              sums.push(reviewNum + '/' + expertNumber);
              break
            case 'finishedNum':
              sums.push(finishedNum + '/' + expertNumber);
              break
            case 'pendingNum':
              sums.push(pendingNum + '/' + expertNumber);
              break
            default:
              sums.push('-');
          }
        }
      })
      // 返回一个二维数组的表尾合计
      return [sums]
    },
    headerCellClassName({
                          column,
                          columnIndex
                        }) {
      return 'vxe-table-thead'
    },
    headerCellStyle({
                      row,
                      column,
                      rowIndex,
                      columnIndex
                    }) {
      return "background-color:#e0e0e0f1"
    },
    scroll(e) {
      // console.log('scroll', e.target.scrollTop)
    },
    async handleInstitutionChange(){
      await this.getInstitution();
      if(this.search.institutionId==""){
        return;
      }
      let r = 0;
      console.log(this.institutionList)
      for (let i = 0; i < this.institutionList.length; i++) {
        if (this.institutionList[i].institutionId == this.search.institutionId) {
          r = 1;
          break;
        }
      }
      if (r == 0 ) {
        // 没有单位信息，单位全部归档
        this.resetHandle();
        this.batchSearch();
      }
      if (r == 1) {
        r = 0;
        await this.getBatch(this.search.institutionId);
        console.log(this.batchList);
        for (let i = 0; i < this.batchList.length; i++) {
          if (this.batchList[i].batchId == this.search.batchId) {
            r = 1;
            break;
          }
        }
        if (r == 0) {
          // 没有批次信息，批次全部归档
          this.search.batchId = '';
          this.batchSearch();
        }
      }
    },
    // 是否显示下拉框
    isShowSelectOptions(isShowSelectOptions){
      if(!isShowSelectOptions) this.$refs.selectInstitution.blur();
      if(!isShowSelectOptions) this.$refs.selectBatchId.blur();
      if(!isShowSelectOptions) this.$refs.selectHaveNote.blur();
      if(!isShowSelectOptions) this.$refs.selectState.blur();
      if(!isShowSelectOptions) this.$refs.selectSendingType.blur();

    },
    async openWebSocket(){
      let token = await this.$api.user.getSession();
      token = token.data.data;
      let url = `ws://localhost:8080/ws/websocket/${token}`;
      this.ws = new WebSocket(url);
      this.ws.onopen = function(e)
      {
        console.log(e)
      };
      this.ws.onmessage = (evt)=>{
        var data = JSON.parse(evt.data);
        this.count += 1;
        this.matchText = "正在匹配中 "+ this.count + "/" + this.paperLength
        this.matchLoading.setText(this.matchText)
        console.log(this.matchText)
      }
    }
  },
  //在页面离开时记录滚动位置
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('changeScrollTop', this.$refs.sendRecord.parentElement.scrollTop);
    next()
  },
  //进入该页面时，用之前保存的滚动位置赋值
  // beforeRouteEnter(to, from, next) {
  //     next(vm => {
  //         console.log(vm.scrollTop);
  //         console.log(vm.$refs)
  //     })
  // },
  components: {

  }
}
</script>

<style scoped>
.inputSelect {
  width: 120px;
}

.collapseInput {
  margin-top: 20px;
}

.searchBtn {
  padding: 20px 0px 0px 0px;
  text-align: center;
}

.box-card {
  padding-bottom: 0px !important;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.form-line-item {
  width: 15em;
}

.el-form-item {
  margin-bottom: 10px !important;
}

.number {
  width: 45% !important;
}

</style>
<style>
.paperPageList .vxe-table--body-wrapper {
  height: calc(100vh - 384px);
}

.dialog .el-dialog {
  margin-top: 5vh !important;
  border-radius: 15px;
}

.searchForm .el-form-item__label {
  background-color: #F5F7FA;
  color: #909399;
}
</style>